import { forwardRef, useEffect, useState } from 'react';
import { DashboardData } from '../../models/dashboard/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';

export type DashboardProps = {};
export type DashboardRef = {};

export const Dashboard = forwardRef<DashboardRef, DashboardProps>((props, ref) => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<DashboardData>();

  useEffect(() => {
    setPageLoading(true);

    const loadDashboardData = async () => {
      try {
        const dashboardData = await DashboardService.getExpressStandardDashboard();

        setDashboard(dashboardData);
      } catch (err) {
        console.error(err);
      } finally {
        setPageLoading(false);
      }
    };

    loadDashboardData();
  }, []);

  return <div className="size-full"></div>;
});
